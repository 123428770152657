import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Switch, Radio, DatePicker } from 'antd';
import AdminsDropDown from '../ui/DataDropdowns/AdminsDropDown';
import ClientsDropDown from '../ui/DataDropdowns/ClientsDropDown';
import MetersDropDown from '../ui/DataDropdowns/MetersDropDown';
import { dateForInput } from '../../utils';

const { Item } = Form;

const AddEditForm = ({
  form,
  meters,
  clientOptions,
  accountsType,
  initialValues,
  isEdit,
}) => {
  const [showSourceClient, setShowSourceClient] = useState(false);
  const isAccount = accountsType === 'accounts';

  useEffect(() => {
    if (initialValues) {
      if (initialValues.trial_end_date !== null)
        initialValues.trial_end_date = dateForInput(
          initialValues.trial_end_date,
        );
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  const onValuesChange = (values) => {
    if ('demo_data' in values) {
      setShowSourceClient(values.demo_data);
    }
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onValuesChange={onValuesChange}
    >
      <Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please account name!' }]}
      >
        <Input />
      </Item>
      <Item
        label="Bucket"
        name="bucket_location"
        rules={[{ required: true, message: 'Please select bucket' }]}
      >
        <Radio.Group>
          <Radio value={'US'}>US</Radio>
          <Radio value={'EU'}>EU</Radio>
          <Radio value={'AS'}>Asia</Radio>
        </Radio.Group>
      </Item>
      <Item
        label="Internal IT admins"
        name="it_admins"
        rules={[{ required: true, message: 'Please select IT admins' }]}
      >
        <AdminsDropDown mode="multiple" />
      </Item>
      {!isAccount && (
        <Item
          label="Trial end date"
          name="trial_end_date"
          rules={[{ required: true, message: 'Please select trial end date' }]}
        >
          <DatePicker format="DD MMM YYYY" />
        </Item>
      )}
      {!(isAccount || isEdit) && (
        <>
          <Item
            label="Demo data"
            name="demo_data"
            valuePropName="checked"
            tooltip="Trial accounts starts with demo data by default, Deactivate this toggle to create an empty trial."
          >
            <Switch checkedChildren="On" unCheckedChildren="Off" />
          </Item>
          {showSourceClient && (
            <Item label="Demo data source" name="source_client_name">
              <ClientsDropDown clientOptions={clientOptions} />
            </Item>
          )}
        </>
      )}
      <Item label="2FA" name="two_fa_enabled">
        <Radio.Group>
          <Radio value={'active'}>On</Radio>
          <Radio value={'inactive'}>Off</Radio>
        </Radio.Group>
      </Item>
      <Item
        label="Activated/Deactivated"
        name="is_active"
        initialValue={true}
        valuePropName="checked"
      >
        <Switch checkedChildren="Activated" unCheckedChildren="Deactivated" />
      </Item>
      <Item label="Meters" name="meters">
        <MetersDropDown mode="multiple" meters={meters} />
      </Item>
    </Form>
  );
};

AddEditForm.propTypes = {
  form: PropTypes.object.isRequired,
  accountsType: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
};

AddEditForm.defaultProps = {
  initialValues: {},
  isEdit: false,
};

export default AddEditForm;
