import { Tag } from 'antd';
import styled from '@emotion/styled';
import { orange, grey, red, green } from '@ant-design/colors';
import React from 'react';

const statusMap = {
  requested: { label: 'Requested', color: grey[1] },
  started: { label: 'Started', color: orange[4] },
  finished: { label: 'Finished', color: green[4] },
  error: { label: 'Error', color: red[3] },
};

const StyledLabel = styled.span`
  color: ${({ value }) => statusMap[value].color};
  font-weight: bold;
`;

export const Has2FA = ({ value }) => {
  return (
    <Tag color={value === 'active' ? 'green' : 'volcano'}>
      {value === 'active' ? 'On' : 'Off'}
    </Tag>
  );
};

export const Status = ({ value }) => {
  return (
    <Tag color={value ? 'green' : 'volcano'}>
      {value ? 'Activated' : 'Deactivated'}
    </Tag>
  );
};

export const DemoData = ({ value }) => {
  if (!value) return '-';
  return (
    <StyledLabel value={value}>{statusMap[value].label || '-'}</StyledLabel>
  );
};
